<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)"
              ref="form_add_or_update"
              class="repeater-form" >
        <template>
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Date</b-th>
              <b-th class="text-center" v-for="(sub,key) in subjects" :key="key">{{ sub.name }}</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(at,k) in attendances" :key="k">
                <b-th class="text-left">{{ k }}</b-th>
                <b-th class="text-center" v-for="(sub,key) in subjects" :key="key">{{ atStatus(at,sub) }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BTableSimple,BThead,BTr,BTh,BTbody,BForm,
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment"
import apiCall from "@/libs/axios";
export default {
  name:'Attendance',
  components: {
    BCardCode,
    BForm, ValidationObserver,
    BTableSimple,BThead,BTr,BTh,BTbody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      attendance_date:null,
      subjects:[],
      attendances:[],
    }
  },
  methods:{
    /*
    * method for get attendance status
    * */
    atStatus(at,sub){
      let ff = at.find(item=>item.subject_id===sub.id);
      if(ff){
        return this.attendanceStatusByID(ff.attendance_status_id);
      }
      else return null;
    },
    async getMaterials(){
      await apiCall.get('/student/personal/attendances').then((response)=>{
        this.attendances=response.data.attendances;
        this.subjects=response.data.subjects;
      }).catch(()=>{
        this.attendances=[];
        this.subjects=[];
      })
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    /*get this employee students*/
    ...mapGetters(['attendance_statuses','attendanceStatusByID']),
  },
  created() {
    this.getMaterials();
    if(this.attendance_statuses.length <1) this.$store.dispatch('GET_ALL_ATTENDANCE_STATUS');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>